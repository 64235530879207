<template>
  <div>
    <h2 class="text-primary">
      {{ $t('pages.auth.forgot-password.title') }} 😨
    </h2>
    <p class="text-lined">
      {{ $t('pages.auth.forgot-password.description') }}
    </p>

    <b-row>
      <b-col
        sm="5"
        class="animate__animated animate__bounceInUp"
      >
        <div
          v-if="loader"
          class="mt-4 mb-4 pt-3 pb-3 d-flex align-items-center justify-content-center flex-fill"
        >
          <b-spinner
            variant="primary"
            type="grow"
            :label="$t('therms.loading')"
          />
        </div>
        <template v-else>
          <validation-observer
            #default="{invalid}"
            ref="passwordForgotForm"
          >
            <b-form
              class="auth-forgot-password-form mt-2 animate__animated"
              :class="{'animate__shakeX':error}"
              @submit.prevent="submit"
            >
              <server-display />
              <b-form-group
                :label="$t('therms.email')"
                label-size="lg"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="emailData"
                    type="email"
                    size="lg"
                    autocapitalize="none"
                    spellcheck="false"
                    autocomplete="off"
                    autocorrect="off"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                    placeholder="john@example.com"
                  />
                </validation-provider>
              </b-form-group>

              <div class="pt-1" />
              <router-link
                tag="a"
                class="text-primary text-up"
                :to="{name:ROUTE_NAME_LOGIN}"
              >
                {{ $t('pages.auth.forgot-password.form.remembered-password') }}
              </router-link>
              <div class="pb-2 pt-1" />

              <touch-button
                class="btn-rounded"
                type="submit"
                color="primary"
                :disabled="invalid || error"
                :disable="invalid || error"
              >
                {{ $t('pages.auth.forgot-password.form.reset') }}
              </touch-button>
            </b-form>
          </validation-observer>
        </template>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import TouchButton from '@core/components/touch-button/TouchButton.vue'
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BSpinner,
} from 'bootstrap-vue'
import ServerDisplay from '@/views/pages/authentication/components/ServerDisplay.vue'
import { required, email } from '@validations'
import { Dialog } from '@capacitor/dialog'
// eslint-disable-next-line import/no-cycle
import { NAME_LOGIN, NAME_SERVER } from '@/router/routes/auth'

export default {
  components: {
    BSpinner,
    TouchButton,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    ServerDisplay,
  },
  data() {
    return {
      loader: false,
      error: false,
      emailData: '',
      // validation
      required,
      email,
    }
  },
  computed: {
    ROUTE_NAME_LOGIN() {
      return NAME_LOGIN
    },
  },
  watch: {
    error() {
      setTimeout(() => {
        if (this.error) {
          this.error = false
        }
      }, 1000)
    },
  },
  async mounted() {
    if (!this.$store.getters['server/isDefine']) await this.$router.push({ name: NAME_SERVER })
  },
  methods: {
    async submit() {
      try {
        if (!(await this.$refs.passwordForgotForm.validate()) || this.error) {
          return
        }

        this.loader = true

        await this.$store.dispatch('auth/passwordForgot', {
          email: this.emailData,
        })

        await this.$router.push({
          name: NAME_LOGIN,
        })

        await Dialog.alert({
          title: this.$t('pages.auth.forgot-password.title').toString(),
          message: this.$t('pages.auth.forgot-password.success-modal.message').toString(),
          buttonTitle: this.$t('therms.validate').toString(),
        })
      } catch (e) {
        this.error = true
      } finally {
        this.loader = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../@core/scss/vue/pages/page-auth.scss';
</style>
